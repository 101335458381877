'use strict';

import { environment } from './environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

// export const FILE_URL = environment.fileUrl;
export const API_BASE_URL = environment.apiUrl;
// export const FILE_BASE_URL = environment.fileBaseUrl;
// export const MOMENT_TIME_FORMAT = 'HH:mm:ss';
// export const MONTHS = [
//   { name: 'มกราคม', value: 1 },
//   { name: 'กุมภาพันธ์', value: 2 },
//   { name: 'มีนาคม', value: 3 },
//   { name: 'เมษายน', value: 4 },
//   { name: 'พฤษภาคม', value: 5 },
//   { name: 'มิถุนายน', value: 6 },
//   { name: 'กรกฎาคม', value: 7 },
//   { name: 'สิงหาคม', value: 8 },
//   { name: 'กันยายน', value: 9 },
//   { name: 'ตุลาคม', value: 10 },
//   { name: 'พฤศจิกายน', value: 11 },
//   { name: 'ธันวาคม', value: 12 },
// ];

// Utility functions
export const clone = obj => JSON.parse(JSON.stringify(obj));
export const validateEmail = function(email) {
  let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
export const onlyNumericCharacter = function(text) {
  return !text.match(/[^$,.\d]/);
};
