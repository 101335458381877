import { Injectable } from "@angular/core";
import { StarRatingConfigService } from "angular-star-rating";

@Injectable()
export class StarConfigService extends StarRatingConfigService {

  constructor() {
    super();
    this.numOfStars = 4;
    this.staticColor = 'positive';
  }
}