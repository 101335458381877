import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { StarRatingConfigService, StarRatingModule } from 'angular-star-rating';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxStarsModule } from 'ngx-stars';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { JwtModule } from '@auth0/angular-jwt';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LightboxModule } from 'ngx-lightbox';

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgChartsModule } from 'ng2-charts';
import { AuthGuardService } from './services/auth-guard.service';
import { StarConfigService } from './services/star-config.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GoogleMapsModule } from '@angular/google-maps';

export function jwtTokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    GoogleMapsModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    FormsModule,
    StarRatingModule.forRoot(),
    NgxMaskModule.forRoot(),
    LightboxModule,
    JwtModule.forRoot({
      config: { tokenGetter: jwtTokenGetter }
    }),
    NgxStarsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    PdfViewerModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    HttpClientModule,
    CollapseModule.forRoot(),
    NgChartsModule,
    ModalModule.forRoot(),
    SweetAlert2Module.forRoot()
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
  ],
  providers: [
    AuthGuardService,
    BsDatepickerConfig,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: StarRatingConfigService, useClass: StarConfigService
    }
  ],
  exports: [
    ModalModule
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
