import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor() {}
  canActivate(): boolean {
    const officerId = localStorage.getItem('DGRADMIN_OFFICER_ID');
    return (officerId) ? true : false;
  }
}
