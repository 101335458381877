import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';
import { AniccomHttpUrlEncodingCodec } from '../utils/aniccom-http-url-encoding-codec';
import { Staff } from '../models/staff.model';
import * as globals from '../../globals';
import { format, endOfMonth } from 'date-fns';
import { th } from 'date-fns/locale';
import { Router } from '@angular/router';
import { User } from '../models/user.model';
import { Knowledge } from '../models/knowledge.model';
import { News } from '../models/news.model';
import { Driller } from '../models/driller.model';
import { Content } from '../models/content.model';
import { Role } from '../models/role.model';
import { Banner } from '../models/banner.model';
import { InformReason } from '../models/inform-reason.model';
import { ExternalOrganization } from '../models/external-organization.model';
import { Inform } from '../models/inform.model';
import { Province } from '../models/province.model';
import { Rating } from '../models/rating.model';
import { InformType } from '../models/inform-type.model';
import { Officer } from '../models/officer.model';
import { startOfDay, endOfDay } from 'date-fns';
import { InformerSource } from '../models/informer-source.model';
import { InformAssignment } from '../models/inform-assignment.model';
// import {  } from 'date-fns/locale/th';

@Injectable()
export class ApiService {
  token: string;
  headers: any;
  basicParams: any;
  dataUrl = `${globals.API_BASE_URL}`;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    this.token = localStorage.getItem('DGRADMIN_TOKEN');
    this.headers = new HttpHeaders({
      Authorization: `Bearer ${this.token}`
    });
    this.basicParams = new HttpParams({
      encoder: new AniccomHttpUrlEncodingCodec()
    });
  }

  private get(
    endpoint: string,
    headers: any,
    params: any = undefined
  ): Observable<HttpResponse<any>> {
    const url = `${this.dataUrl}/${endpoint}`;
    return this.http.get<any>(url, {
      params: params,
      headers: new HttpHeaders({
        ...headers
      }),
      observe: 'response'
    });
  }

  pingApiServer() {
    return this.http.get<any>(`https://badanadmin.dgr.go.th/api/inform-type`, { observe: 'response' });
  }

  pingGisServer() {
    return this.http.get(`https://gisportal.dgr.go.th/arcgis/rest/services/DGR_SERVICE`,
      { observe: 'response', responseType: 'text' });
  }

  login(email: string, password: string) {
    const loginData = { EMAIL: email, PASSWORD: password };
    return this.http.post<any>(`${this.dataUrl}/api/officer/login`, loginData, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getOilPrice() {
    return this.http.get<any>(`${this.dataUrl}/api/setting/oil-price`, { params: this.basicParams, headers: this.headers });
  }

  getSettings() {
    return this.http.get<Array<any>>(`${this.dataUrl}/api/setting`, { params: this.basicParams, headers: this.headers });
  }

  updateSettings(keys: Array<string>, values: Array<string>) {
    const data = { SETTING_VALUES: values, SETTING_KEYS: keys };
    return this.http.post<any>(`${this.dataUrl}/api/setting/update`, data, { params: this.basicParams, headers: this.headers });
  }

  getUsers(query: any = {}) {

    let params: HttpParams = new HttpParams({
      encoder: new AniccomHttpUrlEncodingCodec()
    });
    if (query && query.START_DATE) {
      params = params.append('where[createdAt][gte]', startOfDay(new Date(query.START_DATE)).toISOString());
    }
    if (query && query.END_DATE) {
      params = params.append('where[createdAt][lte]', endOfDay(new Date(query.END_DATE)).toISOString());
    }

    return this.http.get<Array<User>>(`${this.dataUrl}/api/user`, { params: params, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getUser(id: string) {
    return this.http.get<User>(`${this.dataUrl}/api/user/${id}`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  addUser() {
    const params: HttpParams = new HttpParams({
      encoder: new AniccomHttpUrlEncodingCodec()
    });
  }

  updateUser(user: User) {
    return this.http.post<User>(`${this.dataUrl}/api/user/update/${user.USER_ID.toString()}`, user, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  addAttachment(informAttach: any) {
    return this.http.post<User>(`${this.dataUrl}/api/inform-attachment`, informAttach, { params: this.basicParams, headers: this.headers })
  }

  deleteAttachment(id: string) {
    return this.http.post<any>(`${this.dataUrl}/api/inform-attachment/delete/${id}`, { headers: this.headers });
  }

  getContent(id: string) {
    return this.http.get<Content>(`${this.dataUrl}/api/content/${id}`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getContentByCode(code: string) {
    return this.http.get<Content>(`${this.dataUrl}/api/content/code/${code}`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateContent(content: Content) {
    return this.http.post<any>(`${this.dataUrl}/api/content/update/${content.CONTENT_ID}`, content, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getDrillers() {
    return this.http.get<Array<Driller>>(`${this.dataUrl}/api/driller`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateDriller(driller: Driller) {
    return this.http.post<Driller>(`${this.dataUrl}/api/driller/update/${driller.OBJECTID}`, driller, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  deleteDriller(id: string) {
    return this.http.post<any>(`${this.dataUrl}/api/driller/delete/${id}`, { headers: this.headers });
  }

  getOfficers() {
    return this.http.get<Array<Officer>>(`${this.dataUrl}/api/officer`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getOfficer(id: string) {
    return this.http.get<Officer>(`${this.dataUrl}/api/officer/${id}`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateOfficer(officer: Officer) {
    return this.http.post<any>(`${this.dataUrl}/api/officer/update/${officer.OFFICER_ID}`, officer, { headers: this.headers })
  }

  getRoles() {
    return this.http.get<Array<Role>>(`${this.dataUrl}/api/role`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getRole(id: string) {
    return this.http.get<Array<Role>>(`${this.dataUrl}/api/role/${id}`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  addRole(role: Role) {
    return this.http.post<Role>(`${this.dataUrl}/api/role`, role, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateRole(role: Role) {
    return this.http.post<Role>(`${this.dataUrl}/api/role/update/${role.ROLE_ID}`, role, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  deleteRole(id: string) {
    return this.http.post<any>(`${this.dataUrl}/api/role/delete/${id}`, { headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getBanners() {
    return this.http.get<Array<Banner>>(`${this.dataUrl}/api/banner`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getBanner(id: string) {
    return this.http.get<Array<Banner>>(`${this.dataUrl}/api/banner/${id}`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  addBanner(banner: Banner) {
    return this.http.post<Banner>(`${this.dataUrl}/api/banner`, banner, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateBanner(banner: Banner) {
    return this.http.post<Role>(`${this.dataUrl}/api/banner/update/${banner.BANNER_ID}`, banner, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  deleteBanner(id: string) {
    return this.http.post<any>(`${this.dataUrl}/api/banner/delete/${id}`, { headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getExternalOrgs() {
    return this.http.get<Array<ExternalOrganization>>(`${this.dataUrl}/api/external-organization`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getInformTypes() {
    return this.http.get<Array<InformType>>(`${this.dataUrl}/api/inform-type`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getInformReasons() {
    return this.http.get<Array<InformReason>>(`${this.dataUrl}/api/inform-reason`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getPdpaContents() {
    return this.http.get<Array<Content>>(`${this.dataUrl}/api/content/category/pdpa`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getOfficerInforms(officerId: string, query: any = {}) {

    let params: HttpParams = new HttpParams({
      encoder: new AniccomHttpUrlEncodingCodec()
    });
    if (query && query.INFORM_NO) {
      params = params.append('where[INFORM_NO][like]', query.INFORM_NO);
    }
    if (query && query.INFORM_TYPE_ID) {
      params = params.append('where[INFORM_TYPE_ID]', query.INFORM_TYPE_ID);
    }
    if (query && query.START_DATE) {
      params = params.append('where[INFORM_DATE][gte]', startOfDay(new Date(query.START_DATE)).toISOString());
    }
    if (query && query.END_DATE) {
      params = params.append('where[INFORM_DATE][lte]', endOfDay(new Date(query.END_DATE)).toISOString());
    }
    if (query && query.STATUS) {
      params = params.append('where[STATUS]', query.STATUS);
    }
    if (query && query.ZONE_ID) {
      params = params.append('where[ZONE_ID]', query.ZONE_ID);
    }
    if (query && query.NAME) {
      params = params.append('NAME', query.NAME);
    }
    if (query && query.TEL) {
      params = params.append('TEL', query.TEL);
    }

    return this.http.get<Array<Inform>>(`${this.dataUrl}/api/officer/${officerId}/inform`, { params: params, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getInforms(query: any = {}) {
    let params: HttpParams = new HttpParams({
      encoder: new AniccomHttpUrlEncodingCodec()
    });
    if (query && query.ZONE_ID) {
      params = params.append('where[ZONE_ID]', query.ZONE_ID);
    }
    if (query && query.START_DATE) {
      params = params.append('where[INFORM_DATE][gte]', startOfDay(new Date(query.START_DATE)).toISOString());
    }
    if (query && query.END_DATE) {
      params = params.append('where[INFORM_DATE][lte]', endOfDay(new Date(query.END_DATE)).toISOString());
    }
    return this.http.get<Array<Inform>>(`${this.dataUrl}/api/inform`, { params: params, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getInform(id: string) {
    return this.http.get<Array<Inform>>(`${this.dataUrl}/api/inform/${id}`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getInformDetail(id: string) {
    return this.http.get<Array<Inform>>(`${this.dataUrl}/api/inform/${id}/update-log`, { params: this.basicParams, headers: this.headers });
  }

  addInform(inform: Inform) {
    return this.http.post<Inform>(`${this.dataUrl}/api/inform`, inform, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateInform(inform: Inform) {
    return this.http.post<Inform>(`${this.dataUrl}/api/inform/update/${inform.INFORM_ID}`, inform, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getInformAssignment(informId: string) {
    return this.http.get<Array<InformAssignment>>(`${this.dataUrl}/api/inform/${informId}/assignment`, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  addAssignment(assign: InformAssignment) {
    return this.http.post<InformAssignment>(`${this.dataUrl}/api/inform-assignment`, assign, { headers: this.headers });
  }

  updateAssignment(assign: InformAssignment) {
    return this.http.post<InformAssignment>(`${this.dataUrl}/api/inform-assignment/update/${assign.ASSIGN_ID}`, assign, { headers: this.headers });
  }

  deleteAssignment(id: string) {
    return this.http.post<any>(`${this.dataUrl}/api/inform-assignment/delete/${id}`, { headers: this.headers });
  }

  getLoginLogs(query: any = {}) {
    let params: HttpParams = new HttpParams({
      encoder: new AniccomHttpUrlEncodingCodec()
    });
    if (query && query.START_DATE) {
      params = params.append('where[LOGIN_DATE][gte]', startOfDay(new Date(query.START_DATE)).toISOString());
    }
    if (query && query.END_DATE) {
      params = params.append('where[LOGIN_DATE][lte]', endOfDay(new Date(query.END_DATE)).toISOString());
    }
    return this.http.get<Array<any>>(`${this.dataUrl}/api/log-login`, { params: params, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getRatings(query: any = {}) {
    let params: HttpParams = new HttpParams({
      encoder: new AniccomHttpUrlEncodingCodec()
    });
    if (query && query.START_DATE) {
      params = params.append('where[createdAt][gte]', startOfDay(new Date(query.START_DATE)).toISOString());
    }
    if (query && query.END_DATE) {
      params = params.append('where[createdAt][lte]', endOfDay(new Date(query.END_DATE)).toISOString());
    }
    return this.http.get<Array<Rating>>(`${this.dataUrl}/api/rating`, { params: params, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getZones(query: any = {}) {
    return this.http.get<Array<Zone>>(`${this.dataUrl}/api/zone`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getZoneOfficer(zoneId: string) {
    return this.http.get<Array<Officer>>(`${this.dataUrl}/api/zone/${zoneId}/officer`, { params: this.basicParams, headers: this.headers })
  }

  getMapWells() {
    return this.http.get<Array<any>>(`${this.dataUrl}/api/province-map`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }
  
  updateDrillers(provinceId: string, imageName: string, updateText: string) {
    const data = {
      IMAGE: imageName,
      UPDATE_TXT: updateText
    };
    return this.http.post<any>(`${this.dataUrl}/api/province-map/update/${provinceId}`, data, { headers: this.headers });
  }
  
  getProvinces() {
    return this.http.get<Array<Province>>(`${this.dataUrl}/api/province`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getInformerSource() {
    return this.http.get<Array<InformerSource>>(`${this.dataUrl}/api/informer-source`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getKnowledgesByCate(cateName: string) {
    return this.http.get<any>(`${this.dataUrl}/api/knowledge/category/${cateName}?admin=true`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  importDriller(file: any) {
    const headers = {
      Authorization: `Bearer ${this.token}`
    };
    const formData = new FormData();
    formData.append('file', file);
    formData.append('TABLE', 'Driller');
    file.inProgress = true;
    const url = `${this.dataUrl}/api/import`;

    return this.http.post<any>(url, formData, {
      headers: new HttpHeaders({
        ...headers
      }),
      observe: 'response'
    });

  }

  addDrillers(data: any) {
    return this.http.post<any>(`${this.dataUrl}/api/driller/import`, data, { headers: this.headers });
  }

  hideKnowledge(id: string) {
    return this.http.post<any>(`${this.dataUrl}/api/knowledge/update/${id}`, { KNOWLEDGE_STATUS: false }, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  showKnowledge(id: string) {
    return this.http.post<any>(`${this.dataUrl}/api/knowledge/update/${id}`, { KNOWLEDGE_STATUS: true }, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getDashboardOverall(start: any, end: any, zone: any) {
    let params: HttpParams = new HttpParams({
      encoder: new AniccomHttpUrlEncodingCodec()
    });
    if (start) {
      params = params.append('start', start);
    }
    if (end) {
      params = params.append('end', end);
    }
    if (zone) {
      params = params.append('zone', zone);
    }
    return this.http.get<any>(`${this.dataUrl}/api/dashboard/overall`, { params: params, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getDashboardStat(start: any, end: any) {
    return this.http.get<any>(`${this.dataUrl}/api/dashboard/stat?start=${start}&end=${end}`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getDashboardInform(start: any, end: any, zone: any) {
    let params: HttpParams = new HttpParams({
      encoder: new AniccomHttpUrlEncodingCodec()
    });
    if (start) {
      params = params.append('start', start);
    }
    if (end) {
      params = params.append('end', end);
    }
    if (zone) {
      params = params.append('zone', zone);
    }
    return this.http.get<any>(`${this.dataUrl}/api/dashboard/inform`, { params: params, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getDashboardRating(start: any, end: any) {
    return this.http.get<any>(`${this.dataUrl}/api/dashboard/rating?start=${start}&end=${end}`, { params: this.basicParams, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  /**
     * MASTER DATA
     */
  getMasterData(dataName: String) {
    const endpoint = `api/${dataName}`;
    const headers = {
      Authorization: `Bearer ${this.token}`
    };
    return this.get(endpoint, headers).pipe();
  }

  addMasterData(dataName: String, item: any) {
    return this.http.post<any>(`${this.dataUrl}/api/${dataName}`, item, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  updateMasterData(dataName: String, id: string, item: any) {
    return this.http.post<any>(`${this.dataUrl}/api/${dataName}/update/${id}`, item, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  deleteMasterData(dataName: String, id: string) {
    return this.http.post<any>(`${this.dataUrl}/api/${dataName}/delete/${id}`, {}, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  uploadfile(base64: string) {
    return this.http.post<any>(`${this.dataUrl}/file/base64`, { data: base64 }, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  uploadFile(file: any) {
    const formData = new FormData();
    formData.append('file', file);
    file.inProgress = true;
    return this.http.post<any>(`${this.dataUrl}/file`, formData, { headers: this.headers });
  }

  importPrice(data: string) {
    const obj = { data };
    return this.http.post<any>(`${this.dataUrl}/api/setting/price/import`, obj, { headers: this.headers });
  }

  updatePrice(objectId: string, PRICE: string, PRICE_TYPE: string) {
    const obj = { PRICE, PRICE_TYPE: PRICE_TYPE.toLowerCase() };
    return this.http.post<any>(`${this.dataUrl}/api/setting/price/update/${objectId}`, obj, { headers: this.headers });
  }

  confirmUpdatePrice(updateData: any) {
    return this.http.post<any>(`${this.dataUrl}/api/setting/price/update`, updateData, { headers: this.headers });
  }

  getAllPrice() {
    return this.http.get<any>(`${this.dataUrl}/api/setting/price`, { params: this.basicParams, headers: this.headers })
  }

  getData(query: any = {}) {
    const params: HttpParams = new HttpParams({
      encoder: new AniccomHttpUrlEncodingCodec()
    });
    // if (query && query.zone_id_in) {
    //   params = params.append('where[zone_id][in]', query.zone_id_in);
    // }
    return this.http.get<Array<Staff>>(this.dataUrl, { params: params, headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  getDailyReportUrl(year: number, month: number /* 1 - 12 */) {
    const startDate = new Date(Number(year), month - 1);
    const endDate = endOfMonth(startDate);
    return `${this.dataUrl}questionnaire/report/daily/report-daily-${year}-${month}.xlsx?start=${format(startDate, 'yyyy-MM-dd')}&end=${format(endDate, 'yyyy-MM-dd')}`;
  }

  getSingleData(id: string) {
    return this.http.get<Staff>(this.dataUrl + '/' + id, { headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  postData(staff: Staff) {
    return this.http.post<Staff>(this.dataUrl, staff, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  putData(staff: Staff) {
    return this.http.post<Staff>(`${this.dataUrl}/update/${staff.staff_id}`, staff, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  deleteData(id: number) {
    return this.http.delete<any>(`${this.dataUrl}/${id}`, { headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }

  postAssign(user_id: number, questionnaire_type: String) {
    return this.http.get<any>(`${this.dataUrl}test/assign/${questionnaire_type}/${user_id}`, { headers: this.headers })
      .pipe(
        catchError(this.handleError.bind(this)) // then handle the error
      );
  }
  
  postLog(logData: any) {
    return this.http.post<Staff>(`${this.dataUrl}/api/action-log`, logData, { headers: this.headers })
      .pipe(
        // catchError(this.handleError.bind(this)) // then handle the error
      );
  }


  handleError(error: HttpErrorResponse) {
    // globals.apiErrorHandler(this.authenService, this.router, error);
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
