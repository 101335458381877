import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './views/login/login.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    component: LoginComponent,
    path: 'login',
    loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule),
    data: {
      title: 'Login Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'userList',
        loadChildren: () => import('./views/user/user-list/user-list.module').then(m => m.UserListInitModule)
      },
      {
        path: 'knowledge',
        loadChildren: () => import('./views/knowledge/knowledge.module').then(m => m.KnowledgeInitModule)
      },
      {
        path: 'news',
        loadChildren: () => import('./views/news/news.module').then(m => m.NewsInitModule)
      } ,
      {
        path: 'participate',
        loadChildren: () => import('./views/participate/participate.module').then(m => m.ParticipateInitModule)
      },
      {
        path: 'ticket',
        loadChildren: () => import('./views/ticket-list/ticket-list.module').then(m => m.TicketListInitModule)
      },
      {
        path: 'price-list',
        loadChildren: () => import('./views/price-list/price-list.module').then(m => m.PriceListModule)
      },
      {
        path: 'price',
        loadChildren: () => import('./views/price/price.module').then(m => m.PriceInitModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminInitModule)
      },
      {
        path: 'log',
        loadChildren: () => import('./views/log/log.module').then(m => m.LogInitModule)
      },
      {
        path: 'report',
        loadChildren: () => import('./views/report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'setting',
        loadChildren: () => import('./views/setting/setting.module').then(m => m.SettingModule)
      },
      {
        path: 'banner',
        loadChildren: () => import('./views/banner-list/banner-list.module').then(m => m.BannerListModule)
      },
      {
        path: 'consent-manage',
        loadChildren: () => import('./views/consent-manage/consent-manage.module').then(m => m.ConsentManageModule)
      },
      {
        path: 'map-well',
        loadChildren: () => import('./views/map-well/map-well.module').then(m => m.MapWellModule)
      },
      // Master data
      {
        path: 'inform-type',
        loadChildren: () => import('./views/master-data/master-data.module').then(m => m.MasterDataModule)
      },
      {
        path: 'informer-source',
        loadChildren: () => import('./views/master-data/master-data.module').then(m => m.MasterDataModule)
      },
      {
        path: 'external-org',
        loadChildren: () => import('./views/master-data/master-data.module').then(m => m.MasterDataModule)
      },
      {
        path: 'inform-reason',
        loadChildren: () => import('./views/master-data/master-data.module').then(m => m.MasterDataModule)
      },
      {
        path: 'driller',
        loadChildren: () => import('./views/driller/driller.module').then(m => m.DrillerModule)
      },
      {
        path: 'manual',
        loadChildren: () => import('./views/manual/manual.module').then(m => m.ManualModule)
      }
      // End master data
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
