import { Component, HostListener } from '@angular/core';
import { AuthGuardService } from '../../services/auth-guard.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { navItems } from '../../_nav';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { thBeLocale } from 'ngx-bootstrap/locale';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  providers: [ ]
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems2;
  public timerId: any = null;
  public TIMEOUT_MINUTE = 40;
  public name = '';
  public email = '';

  constructor(private localeService: BsLocaleService, private router: Router) {
    defineLocale('th-be', thBeLocale);
    this.localeService.use('th-be');

    this.navItems2 = JSON.parse(JSON.stringify(navItems));

    
    this.name = localStorage.getItem('DGRADMIN_FULLNAME');
    this.email = localStorage.getItem('DGRADMIN_EMAIL');

    if (localStorage.getItem('DGRADMIN_PERM_SETTINGS') != '1') {
      this.navItems2.splice(10, 1);
    }
    if (localStorage.getItem('DGRADMIN_PERM_LOG') != '1') {
      this.navItems2.splice(9, 1);
    }
    if (localStorage.getItem('DGRADMIN_PERM_REPORT') != '1') {
      this.navItems2.splice(8, 1);
    }
    if (localStorage.getItem('DGRADMIN_PERM_DATA') != '1') {
      this.navItems2.splice(7, 1);
    }
    if (localStorage.getItem('DGRADMIN_PERM_ADMIN') != '1') {
      this.navItems2.splice(6, 1);
    }
    if (localStorage.getItem('DGRADMIN_PERM_USER') != '1') {
      this.navItems2.splice(5, 1);
    }
    if (localStorage.getItem('DGRADMIN_PERM_PRICE') != '1') {
      this.navItems2.splice(4, 1);
    }
    if (localStorage.getItem('DGRADMIN_PERM_PROMOTION') != '1') {
      this.navItems2.splice(3, 1);
    }
    if (localStorage.getItem('DGRADMIN_PERM_KNOWLEDGE') != '1') {
      this.navItems2.splice(2, 1);
    }
    
    this.timerId = setTimeout(() => {
      this.logout();
    }, 1000 * 60 * this.TIMEOUT_MINUTE);
  }

  /*@HostListener("window:beforeunload",["$event"])
  clearLocalStorage(event){
    localStorage.clear();
  }*/

  onMenuClick() {
    clearTimeout(this.timerId);
    console.log('Extend Timeout');
    this.timerId = setTimeout(() => {
      this.logout();
    }, 1000 * 60 * this.TIMEOUT_MINUTE);
  }

  navigateTo(item) {
    // navigate to the specified route using the Angular Router
    this.router.navigate([item.route]);
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/', 'login']);
  }
}
