import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, retry, first } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  providers: [ ApiService ]
})
export class LoginComponent implements OnInit {
  identifier: string = '';
  password: string = '';
  returnUrl: string;
  error: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService
  ) {
    // redirect to home if already logged in
    const OFFICER_ID = localStorage.getItem('DGRADMIN_OFFICER_ID');
    console.log(OFFICER_ID);
    if (OFFICER_ID) {
      this.router.navigate(['/', 'dashboard']);
    }
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  login() {
    this.apiService.login(this.identifier, this.password)
      .subscribe(
        async data => {
          if (data.RESULT) {
            console.log(data);
            localStorage.setItem('DGRADMIN_TOKEN', data.TOKEN);
            localStorage.setItem('DGRADMIN_OFFICER_ID', data.OFFICER.OFFICER_ID);
            localStorage.setItem('DGRADMIN_FULLNAME', data.OFFICER.FULLNAME);
            localStorage.setItem('DGRADMIN_EMAIL', data.OFFICER.EMAIL);

            // data.Role.ROLE_ID
            localStorage.setItem('DGRADMIN_PERM_UPDATE_INFORM', data.OFFICER.Role.PERM_UPDATE_INFORM);
            localStorage.setItem('DGRADMIN_PERM_KNOWLEDGE', data.OFFICER.Role.PERM_KNOWLEDGE);
            localStorage.setItem('DGRADMIN_PERM_PRICE', data.OFFICER.Role.PERM_PRICE);
            localStorage.setItem('DGRADMIN_PERM_PROMOTION', data.OFFICER.Role.PERM_PROMOTION);
            localStorage.setItem('DGRADMIN_PERM_DASHBOARD', data.OFFICER.Role.PERM_DASHBOARD);
            localStorage.setItem('DGRADMIN_PERM_USER', data.OFFICER.Role.PERM_USER);
            localStorage.setItem('DGRADMIN_PERM_ADMIN', data.OFFICER.Role.PERM_ADMIN);
            localStorage.setItem('DGRADMIN_PERM_DATA', data.OFFICER.Role.PERM_DATA);
            localStorage.setItem('DGRADMIN_PERM_MAP', data.OFFICER.Role.PERM_MAP);
            localStorage.setItem('DGRADMIN_PERM_REPORT', data.OFFICER.Role.PERM_REPORT);
            localStorage.setItem('DGRADMIN_PERM_LOG', data.OFFICER.Role.PERM_LOG);
            localStorage.setItem('DGRADMIN_PERM_SETTINGS', data.OFFICER.Role.PERM_SETTINGS);

            await this.delay(500);

            this.router.navigate(['/', 'dashboard']);
          }
        },
        err => {
          this.error = err;
          console.log(err);
          // TODO: Check header and message
        });
  }
}
