import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    // badge: {
    //   variant: 'info',
    //   text: 'NEW'
    // }
  },
  {
    name: 'การแจ้งปัญหา',
    url: '/ticket',
    icon: 'fa fa-bell'
  },
  {
    name: 'องค์ความรู้ / บทความ',
    url: '/knowledge',
    icon: 'fa fa-th-large'
  },
  {
    name: 'การโปรโมท',
    url: '/banner',
    icon: 'fa fa-bullhorn'
  },
  {
    name: 'ราคาเจาะบ่อน้ำบาดาล',
    url: '/price-list',
    icon: 'fa fa-paypal'
  },
  {
    name: 'ผู้ใช้งาน',
    url: '/userList',
    icon: 'fa fa-users'
  },
  {
    name: 'การกำหนดสิทธิ์',
    url: '/admin',
    icon: 'icon-user'
  },
  {
    name: 'ข้อมูลพื้นฐาน',
    url: '/info',
    icon: 'fa fa-line-chart',
    children: [
      {
        name: 'ประเภทการแจ้งปัญหา',
        url: '/inform-type',
        icon: 'fa fa-bullhorn',
      },
      {
        name: 'ช่องทางการแจ้งปัญหา',
        url: '/informer-source',
        icon: 'fa fa-users',
      },
      {
        name: 'หน่วยงานภายนอก',
        url: '/external-org',
        icon: 'fa fa-life-ring',
      },
      {
        name: 'สาเหตุเบื้องต้น',
        url: '/inform-reason',
        icon: 'fa fa-list-ul',
      },
      {
        name: 'ช่างเจาะ',
        url: '/driller',
        icon: 'fa fa-address-card-o',
      }
    ]
  },
  {
    name: 'รายงาน',
    url: '/report',
    icon: 'fa fa-file-text-o'
  },
  {
    name: 'Log',
    url: '/log',
    icon: 'fa fa-database'
  },
  {
    name: 'ตั้งค่า',
    url: '/setting',
    icon: 'fa fa-cog',
    children: [
      {
        name: 'Consent Management',
        url: '/consent-manage',
        icon: 'fa fa-leanpub',
      },
      {
        name: 'แผนที่น้ำบาดาล',
        url: '/map-well',
        icon: 'fa fa-map-o',
      },
      {
        name: 'ขอเข้าร่วมโครงการ',
        url: '/participate',
        icon: 'fa fa-handshake-o',
      },
      {
        name: 'ตั้งค่าระบบ',
        url: '/setting',
        icon: 'fa fa-cog',
      }
    ]
  },
  {
    name: 'คู่มือ',
    url: '/manual',
    icon: 'fa fa-book'
  },
  {
    name: 'บัญชีผู้ใช้',
    url: '/profile',
    icon: 'fa fa-user-circle-o'
  }
];
